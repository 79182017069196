/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

  /* SLIDER ACCUEIL */

  $('.homeslider .prev').click(function() {
    var prevText = $('.slider-text-wrapper .item.active').prev();
    var prevImg = $('.slider-img-wrapper .item.active').prev();
    var nextImg = $('.slider-img-wrapper .item.active').next();


    $('.homeslider .item.active').removeClass('active');
    prevText.addClass('active');
    prevImg.addClass('active');

    if(prevText.prev().length == 0) {
      $('.homeslider .btn-wrapper .prev').prop('disabled', true);
    }
    $('.homeslider .btn-wrapper .next').prop('disabled', false);
  });
  $('.homeslider .next').click(function() {
    var nextText = $('.slider-text-wrapper .item.active').next();
    var nextImg = $('.slider-img-wrapper .item.active').next();

    $('.homeslider .item.active').removeClass('active');
    nextText.addClass('active');
    nextImg.addClass('active');

    if(nextText.next().length == 0) {
      $('.homeslider .btn-wrapper .next').prop('disabled', true);
    }
    $('.homeslider .btn-wrapper .prev').prop('disabled', false);
  });


  $(".owl-carousel").owlCarousel({
      nav: true,
      items: 4,
      margin: 50,
      loop: true,
      navText: ["<img src='/wp-content/uploads/images/picto_droite.png' alt='précédent'>","<img src='/wp-content/uploads/images/picto_gauche.png' alt='suivant'>"]
    }
  );

  /* Crop titre des articles */
  function getWords(str) {
    return str.split(/\s+/).slice(0,5).join(" ");
  }

  $('section.lastnews .content h2 a, section.category .content h2 a, section.homeslider .slider-text-wrapper .item h2 a, section.category .billet h2, section.lastnews .billet h2').each(function() {
    console.log("Crop");
    var yourString = $(this).text();
    $(this).text(getWords(yourString) + ' ...');
  });

  /* Bandeau Zoom sur entreprises en Charente */
  $(".logo-link").on('click', function () {
    var etb = $(this).data('entreprise');
    $(".entreprise-details").removeClass('current');
    $("#" + etb).addClass('current');
  });

  $(".zoom-nav .prev").on('click', function () {
    var id = parseInt($(".entreprise-details.current").attr('id').slice(-1)) - 1;
    if (id === -1){
      id = 8;
    }
    $(".entreprise-details").removeClass('current');
    $("#entreprise" + id).addClass('current');
  });

  $(".zoom-nav .next").on('click', function () {
    var id = parseInt($(".entreprise-details.current").attr('id').slice(-1)) + 1;
    if (id === 9){
      id = 0;
    }
    $(".entreprise-details").removeClass('current');
    $("#entreprise" + id).addClass('current');
  });

  /* MENU */
  $('#menu-menu-principal .menu-item-has-children').hover(
      function () {
        $('#menu-menu-principal .sub-menu').css('display', 'block');
      },
      function () {
        $('#menu-menu-principal .sub-menu').css('display', 'none');
      }
  );

  /* Partage réseaux sociaux */

  $('.share-facebook').on('click', function(e) {
    console.log('test');
    e.preventDefault();
    e.stopPropagation();
    var url = window.location.href;
    window.open('https://www.facebook.com/sharer/sharer.php?u=' + url,
        '_blank',
        'width=800,height=600'
    );
    return false;
  });
  $('.share-twitter').on('click', function(e) {
    e.preventDefault();
    e.stopPropagation();
    var url = window.location.href;
    window.open('https://twitter.com/share?url=' + url,
        '_blank',
        'width=800,height=600'
    );
    return false;
  });
  $('.share-linkedin').on('click', function(e) {
    e.preventDefault();
    e.stopPropagation();
    var url = window.location.href;
    window.open('https://www.linkedin.com/shareArticle?mini=true&amp;url=' + url,
        '_blank',
        'width=800,height=600'
    );
    return false;
  });

  $('.share').on('click', function(e) {
    e.preventDefault();
    $(this).next('.share-content').toggleClass('active');
  });

  /* SLIDER MEMBRES VI */

  setTimeout(membre_vi, 1000);

  function membre_vi() {
    var slider_width = $('.membre-slider .item-wrapper').outerWidth();
    var item_number = $('.membre-slider .move .item').length;
    var click_number = 0;

    if (window.matchMedia("(max-width: 425px)").matches) {
      $('.membre-slider .item').css('width', slider_width/1);
    } else if (window.matchMedia("(max-width: 768px)").matches) {
      $('.membre-slider .item').css('width', slider_width/3);
    } else {
      $('.membre-slider .item').css('width', slider_width/6);
    }

    $('.membre-slider .nav-prev').prop('disabled', true);

    $('.membre-slider .nav-prev').on('click', function () {
      $('.membre-slider .nav-next').prop('disabled', false);
      click_number = click_number - 1;
      if(click_number > 0) {
        var left_position = parseInt($('.membre-slider .move').css('left'));

        if (window.matchMedia("(max-width: 425px)").matches) {
          $('.membre-slider .move').css('left', left_position + slider_width/1);
        } else if (window.matchMedia("(max-width: 768px)").matches) {
          $('.membre-slider .move').css('left', left_position + slider_width/3);
        } else {
          $('.membre-slider .move').css('left', left_position + slider_width/6);
        }



      } else {
        $('.membre-slider .nav-prev').prop('disabled', true);
      }
    });

    $('.membre-slider .nav-next').on('click', function () {
      $('.membre-slider .nav-prev').prop('disabled', false);
      click_number = click_number + 1;

      if (window.matchMedia("(max-width: 425px)").matches) {
        if((click_number) < item_number) {
          var left_position = parseInt($('.membre-slider .move').css('left'));

          $('.membre-slider .move').css('left', left_position - slider_width/1);
        } else {
          $('.membre-slider .nav-next').prop('disabled', true);
        }
      } else if (window.matchMedia("(max-width: 768px)").matches) {
        if((click_number + 3) <= item_number) {
          var left_position = parseInt($('.membre-slider .move').css('left'));

          $('.membre-slider .move').css('left', left_position - slider_width/3);
        } else {
          $('.membre-slider .nav-next').prop('disabled', true);
        }
      } else {
        if((click_number + 6) <= item_number) {
          var left_position = parseInt($('.membre-slider .move').css('left'));

          $('.membre-slider .move').css('left', left_position - slider_width/6);
        } else {
          $('.membre-slider .nav-next').prop('disabled', true);
        }
      }

    });

    if (window.matchMedia("(max-width: 425px)").matches) {
      setInterval(function loop() {
        $( ".membre-slider .move" ).animate({
          left: "-="+slider_width/1,
        }, 300);
      }, 5000);
    } else if (window.matchMedia("(max-width: 768px)").matches) {
      setInterval(function loop() {
        $( ".membre-slider .move" ).animate({
          left: "-="+slider_width/3,
        }, 300);
      }, 5000);
    } else {
      setInterval(function loop() {
        $( ".membre-slider .move" ).animate({
          left: "-="+slider_width/6,
        }, 300);
      }, 5000);
    };

  };

  $('.single-membre .tel').click(function() {
    $(this).next('span').css({'opacity': '1', 'visibility': 'visible'});
  });


  /* RESPONSIVE MENU */
  $('.resp-nav-toggle').on('click', function() {
    $('nav.nav-primary').toggleClass('active');
  });

  if (window.matchMedia("(max-width: 768px)").matches) {
    $('.menu-item-has-children a').on('click', function() {
      $(this).find('ul.sub-menu').toggle();
    });
  }

})(jQuery); // Fully reference jQuery after this point.
